import type { MetaObject } from 'nuxt/schema'

const i18nLinkFilter = ['i18n-can']

const i18nMetaFilter = ['i18n-og']

export default function useCustomHead(input: MetaObject & { title: string }) {
  const i18nHead = useLocaleHead({ addSeoAttributes: true })

  input.htmlAttrs = { ...input.htmlAttrs, ...i18nHead.value.htmlAttrs }

  input.link ||= []
  input.link.push(
    ...(i18nHead.value.link.filter(
      (link) => !i18nLinkFilter.includes(link.hid),
    ) || []),
  )

  input.meta ||= []
  input.meta.push(
    ...(i18nHead.value.meta.filter(
      (meta) => !i18nMetaFilter.includes(meta.hid),
    ) || []),
  )
  input.meta.push(
    ...[
      {
        property: 'og:title',
        content: input.title,
        hid: 'og:title',
      },
    ],
  )

  return useHead(input)
}
